import React, { useState } from 'react';
import Modal from 'react-modal';
import './UserManual.css'; // Import the CSS file

// Apply the app element for accessibility
Modal.setAppElement('#root');

export const UserManual = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <button className="open-modal-button" onClick={openModal}>User Manual</button>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="User Manual Video"
        className="custom-modal-content"
        overlayClassName="custom-modal-overlay"
      >
        <h2>User Manual</h2>
        <button onClick={closeModal} className="close-modal-button">Close</button>
        <div className="video-container">
          <iframe
            src="https://www.loom.com/embed/425e342239db458d8513a3e19e12b852?sid=06480963-8f29-42d9-98b2-a2843ac7d5ba"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="User Manual Video"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};

