import Web3 from "web3";
import ethers from "ethers";
import React, { useState } from 'react';

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export const _getReward = async (
  contract,
  accounts,
  selectedAddress,
  setRewardBalance
) => {
  try {
    console.log("getReward");
    const gasEstimate = await contract.methods.updateRewardFunc().estimateGas({ from: accounts[0] });
    await contract.methods.updateRewardFunc().send({ from: accounts[0], gas: gasEstimate });
    _updateReward(contract, selectedAddress, setRewardBalance);
  } catch (err) {
    console.error("Error in _getReward:", err);
  }
};

export const _getVested = async (
  contract,
  accounts,
  selectedAddress,
  setVestedBalance
) => {
  try {
    const gasEstimate = await contract.methods.updateUnlockedNew().estimateGas({ from: accounts[0] });
    await contract.methods.updateUnlockedNew().send({ from: accounts[0], gas: gasEstimate });
    _updateVested(contract, selectedAddress, setVestedBalance);
  } catch (err) {
    console.error("Error in _getVested:", err);
  }
};

export const _updateReward = async (
  contract,
  selectedAddress,
  setRewardBalance
) => {
  try {
    const rewardBalanceRes = await contract?.methods.rewards(selectedAddress).call();
    const rewardBalance = Web3.utils.fromWei(String(rewardBalanceRes), "ether");
    setRewardBalance(rewardBalance);
    console.log("Updated reward balance:", rewardBalance);
  } catch (err) {
    console.error("Error in _updateReward:", err);
  }
};

export const _updateVested = async (
  contract,
  selectedAddress,
  setVestedBalance
) => {
  try {
    const vestedBalanceRes = await contract?.methods.vestedTokens(selectedAddress).call();
    const vestedBalance = Web3.utils.fromWei(String(vestedBalanceRes), "ether");
    setVestedBalance(vestedBalance);
    console.log("Updated vested balance:", vestedBalance);
  } catch (err) {
    console.error("Error in _updateVested:", err);
  }
};

export const _claimReward = async (
  selectedAddress,
  contract,
  setRewardBalance
) => {
  try {
    const gasEstimate = await contract.methods.getReward().estimateGas({ from: selectedAddress });
    const rewardTransfer = await contract.methods.getReward().send({ from: selectedAddress, gas: gasEstimate });
    console.log("Rewards claimed:", rewardTransfer);
    _updateReward(contract, selectedAddress, setRewardBalance);
  } catch (err) {
    console.error("Error in _claimReward:", err);
  }
};

export const _withDraw = async (
  amount,
  selectedAddress,
  contract
) => {
  try {
    console.log("Withdrawing...");
    const withDrawTokens = Web3.utils.toWei(amount, "ether");
    const gasEstimate = await contract.methods.withdraw(withDrawTokens).estimateGas({ from: selectedAddress });
    await contract.methods.withdraw(withDrawTokens).send({ from: selectedAddress, gas: gasEstimate });
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      console.log("Transaction rejected by user");
      return;
    }
    console.error("Error in _withDraw:", error);
  }
};

export const _stake = async (amount, selectedAddress, contract) => {
  try {
    console.log("Staking...");
    const stakeTokens = Web3.utils.toWei(amount, "ether");
    const gasEstimate = await contract.methods.stake().estimateGas({ value: stakeTokens, from: selectedAddress });
    await contract.methods.stake().send({ value: stakeTokens, from: selectedAddress, gas: gasEstimate });
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      console.log("Transaction rejected by user");
      return;
    }
    console.error("Error in _stake:", error);
  }
};

export const _updateStakeBalance = async (
  contract,
  selectedAddress,
  setStakedBalance,
  setTotalStakedTokens
) => {
  try {
    const totalStakedTokensRes = await contract?.methods.totalStakedTokens().call();
    const totalStakedTokens = Web3.utils.fromWei(String(totalStakedTokensRes), "ether");

    const stakedBalanceRes = await contract?.methods.stakedBalance(selectedAddress).call();
    const stakedBalance = Web3.utils.fromWei(String(stakedBalanceRes), "ether");

    setStakedBalance(stakedBalance);
    setTotalStakedTokens(totalStakedTokens);

    console.log("Updated stake balances:", stakedBalance, totalStakedTokens);
  } catch (err) {
    console.error("Error in _updateStakeBalance:", err);
  }
};
